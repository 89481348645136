import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  provideHttpClient,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';

import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  BsDatepickerModule,
  BsDatepickerConfig,
} from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import {
  NgbDatepickerModule,
  NgbAlertModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FileSaverModule } from 'ngx-filesaver';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth.interceptor';
import { SessionService } from './session.service';
import { CommunicationService } from './views/common/services/communication.service';
import { CustomNotificationsService } from './views/common/services/custom-notifications.service';

import { BlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FileUploadDialogComponent } from './views/common/components/file-upload-dialog/file-upload-dialog.component';
import { CurrencyDialogComponent } from './views/common/components/currency-dialog/currency-dialog.component';
import { LeavesTypeMessageComponent } from './views/common/components/leaves-type-message/leaves-type-message.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Importing components dynamically
const DYNAMIC_COMPONENTS = [
  FileUploadDialogComponent,
  CurrencyDialogComponent,
  LeavesTypeMessageComponent,
  MaintenanceComponent,
];

@NgModule({
  declarations: [
    AppComponent,
    BlankComponent,
    FullComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    ...DYNAMIC_COMPONENTS,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(Approutes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
    }),
    HttpClientModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    TooltipModule.forRoot(),
    NgxSliderModule,
    CommonModule,
    NgbDatepickerModule,
    NgbAlertModule,
    JsonPipe,
    FileSaverModule,
    DragDropModule,
  ],
  providers: [
    provideClientHydration(),
    provideHttpClient(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    SessionService,
    CommunicationService,
    CustomNotificationsService,
    BsDatepickerConfig,
    DatePipe,
  ],
  bootstrap: [AppComponent],
  exports: [SidebarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
