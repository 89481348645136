import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SessionService } from '../../session.service';
import { CommunicationService } from '../../views/common/services/communication.service';
import { CompanySettingsServerResultsService } from '../../views/common/services/company-settings-server-results.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

  constructor(private sanitizer: DomSanitizer, public router: Router, private sessionServ: SessionService, public commuServ: CommunicationService, private settingsServer: CompanySettingsServerResultsService) {
    this.sessionServ.openRecordDetails.subscribe((res: any) => {
      this.showSettings = res;
    })
  }

  public sidebarOpen = true;
  public isCollapsed = false;
  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;
  public isBoxedLayout: boolean = localStorage.getItem('layout') === 'Box' ? true : false; // Default value;

  companyDetails: any;

  options = {
    theme: 'light', // two possible values: light, dark
    sidebartype: 'mini-sidebar', // four possible values: full, iconbar, overlay, mini-sidebar
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  closeForm() {
    this.showSettings = false;
  }

  ngOnInit() {
    this.showSettings = false;
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();
    this.settingsServer.getCompanyById(1).subscribe((res: any) => {
      this.companyDetails = res.data;
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 767) {
      this.showMobileMenu = true;
      this.isCollapsed = false;
      this.sidebarOpen = false;
      this.options.sidebartype = 'overlay-sidebar';
    } else {
      this.showMobileMenu = false;
      this.isCollapsed = false;
      this.sidebarOpen = true;
      this.options.sidebartype = 'mini-sidebar';
    }
  }

  toggleSidebar() {
    if (this.showMobileMenu == true) {
      this.isCollapsed = false;
      this.sidebarOpen = !this.sidebarOpen;
      this.options.sidebartype = 'overlay-sidebar';
    } else {
      this.isCollapsed = !this.isCollapsed;
      this.options.sidebartype = this.isCollapsed == true ? 'mini-sidebar' : 'full-sidebar';
    }

    setTimeout(() => {
      // this event resizes all the ngx tables when sidebar toggles
      window.dispatchEvent(new Event('resize'));
    }, 250)
  }

  toggleLayout() {
    this.isBoxedLayout = !this.isBoxedLayout;
    setTimeout(() => {
      // this event resizes all the ngx tables when sidebar toggles
      window.dispatchEvent(new Event('resize'));
    }, 250)
  }
}
