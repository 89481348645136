import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "./session.service";
import { Observable } from "rxjs";
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  // BUG Angular 4.3: we cannot inject the provider
  // constructor(private authService: AuthService) {}
  // FIX: we need to manually use the injector
  tag_key: String = "@Resource.Planner_2020";
  constructor(
    private injector: Injector,
    private router: Router,
    private sessionServ: SessionService
  ) { }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var decryptedKey = "";
    var originalKey = "";
    if (
      sessionStorage.getItem("access_key") != null &&
      sessionStorage.getItem("access_key") != undefined
    ) {
      const decryptWithAES = (ciphertext: any, tag_key: any) => {
        const passphrase = tag_key;
        const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return originalText;
      };
      decryptedKey = decryptWithAES(
        sessionStorage.getItem("access_key"),
        this.tag_key
      );
      originalKey = decryptedKey.toString();
    }
    const modified = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + originalKey),
    });
    return next.handle(modified);
  }
}