<div class="container-fluid" [ngClass]="{ 'overflow-hidden': showSettings, 'boxed-layout': isBoxedLayout }">
  <!-- Sidebar -->
  <div class="sidebar" [ngClass]="{ 
  'open': this.sidebarOpen == true && this.isCollapsed == false, 
  'closed': this.sidebarOpen == false && this.isCollapsed == false,
  'collapsed' : this.sidebarOpen == true && this.isCollapsed == true
  }">
    <i class="mdi mdi-menu-open text-lg toggle-button" (click)="toggleSidebar()"
      *ngIf="options.sidebartype === 'overlay-sidebar'"></i>
    <app-sidebar [isCollapsed]="isCollapsed" [sidebarOpen]="sidebarOpen" [sidebarType]="options.sidebartype"
      (toggleSidebar)="toggleSidebar()"></app-sidebar>
  </div>

  <!-- Main Content Area -->
  <div class="main-content" [ngClass]="{ 
  'open': this.sidebarOpen == true && this.isCollapsed == false, 
  'closed': this.sidebarOpen == false && this.isCollapsed == false,
  'collapsed' : this.sidebarOpen == true && this.isCollapsed == true
  }">
    <app-header (toggleSidebarEvent)="toggleSidebar()" (toggleLayoutEvent)="toggleLayout()" [isCollapsed]="isCollapsed"
      [sidebarOpen]="sidebarOpen" [sidebarType]="options.sidebartype"></app-header>
    <div class="content-area flex-grow-1"> <!-- Container for content -->
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>
</div>