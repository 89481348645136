import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionService } from '../../session.service';
import { CommunicationService } from '../../views/common/services/communication.service';
import { CompanySettingsServerResultsService } from '../../views/common/services/company-settings-server-results.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { module_names } from './ModulesNameInDB';
import { UserServerResultsService } from '../../views/common/services/user-server-results.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
})
export class SidebarComponent implements OnInit {
  userInfo: any;
  companyDetails: any;
  userCode: any;
  userName: any;

  showMenu = '';
  showSubMenu = '';
  public isExpanded: boolean = true; // Controls the visibility of the sidebar
  public sidebarnavItems: any[] = [];
  @Input() profileImage: string;
  @Input() isCollapsed: Boolean;
  @Input() sidebarOpen: Boolean;
  @Input() sidebarType: String;
  @Output() toggleSidebar: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  constructor(
    private sessionServ: SessionService,
    public commuServ: CommunicationService,
    private settingsServer: CompanySettingsServerResultsService,
    public sanitizer: DomSanitizer,
    private route: Router,
    private userServ: UserServerResultsService
  ) { }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  addActiveClass(element: any) {
    if (this.sidebarType === 'overlay-sidebar') {
      this.toggleSidebar.emit();
    }

    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  toggleMenu(item: string): void {
    this.showMenu = this.showMenu === item ? '' : item;
  }

  toggleSubMenu(item: string): void {
    this.showSubMenu = this.showSubMenu === item ? '' : item;
  }

  ngOnInit() {
    this.settingsServer.getCompanyById(1).subscribe((res: any) => {
      this.companyDetails = res.data;
    });
    this.userInfo = this.sessionServ.getSessionDetails();
    this.userName =
      this.userInfo.user_details.userData.personalDetails.firstname +
      ' ' +
      this.userInfo.user_details.userData.personalDetails.lastname;
    this.userCode = this.userInfo.user_details.userData.username;
    let sidebarItems = this.sessionServ.getRolesNPermissions();
    this.sidebarnavItems = sidebarItems;
    const userId =
      this.sessionServ.getSessionDetails().user_details.userData._id;
    this.retrieveProfileImage(userId);
  }

  onLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.route.navigateByUrl('/authentication/login');
  }

  checkPermission(permission) {
    return this.sessionServ.hasAccess(module_names.profile_module, permission);
  }

  openProfile() {
    if (this.checkPermission('Read')) {
      this.route.navigateByUrl('/resource-planner/profile');
    }
  }

  userProfile: any;

  retrieveProfileImage(userId) {
    this.userServ.getUserProfileImage(userId).subscribe((response: any) => {
      this.userProfile = response;

    });
  }

  // retrieveRecordForEditAndView(userId) {
  //   this.userServ.getUserById(userId).subscribe((response: any) => {
  //     this.userProfile = response.data.personalDetails.profilePictureBase64;
  //   });
  // }
}
