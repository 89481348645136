<div class="left-sidebar">
  <div class="navbar-logo">
    <b class="logo-icon">
      <span class="logo-text" *ngIf="isCollapsed">
        <img *ngIf="companyDetails?.companyLogoIconBase64" [src]="
            sanitizer.bypassSecurityTrustUrl(
              companyDetails?.companyLogoIconBase64
            )
          " alt="homepage" class="dark-logo" />
      </span>
    </b>
    <span class="logo-text" *ngIf="!isCollapsed">
      <img *ngIf="companyDetails?.companyLogoBase64" [src]="
          sanitizer.bypassSecurityTrustUrl(companyDetails?.companyLogoBase64)
        " alt="homepage" class="dark-logo" />
    </span>
  </div>
  <nav class="sidebar-nav scroll-sidebar">
    <ul id="sidebarnav" *ngFor="let sidebarItem of sidebarnavItems">
      <li class="nav-small-cap" *ngIf="sidebarItem.submenu.length > 0">
        <i class="mdi mdi-dots-horizontal nav-small-cap-icon" *ngIf="isCollapsed"></i>
        <span class="hide-menu" *ngIf="!isCollapsed">{{
          sidebarItem.title
          }}</span>
      </li>
      <li class="sidebar-item" [class.collapsed]="isCollapsed" *ngFor="let sidebarnavSubItem of sidebarItem.submenu">
        <a class="sidebar-link" [routerLink]="[sidebarnavSubItem.path]" [routerLinkActive]="'router-link-active'"
          [ngClass]="[sidebarnavSubItem.class]" *ngIf="!sidebarnavSubItem.extralink"
          (click)="addActiveClass(sidebarnavSubItem.title)">
          <i [ngClass]="[sidebarnavSubItem.icon]"></i>
          <span class="hide-menu" *ngIf="!isCollapsed">{{
            sidebarnavSubItem.title
            }}</span>
          <!-- <span class="tooltip-text" *ngIf="isCollapsed">{{ sidebarnavSubItem.title }}</span> -->
        </a>
      </li>
    </ul>
  </nav>
  <div class="fixed-profile mx-1 mb-2 bg-secondary-subtle rounded mt-3">
    <div class="hstack">
      <div class="img" *ngIf="!isCollapsed" (click)="openProfile()">
        <img *ngIf="userProfile?.data?.profilePictureBase64 == null" src="../assets/images/user.jpg"
          class="rounded-circle mb-3" width="40" height="40" />
        <img *ngIf="userProfile?.data?.profilePictureBase64 != null" [src]="
              sanitizer.bypassSecurityTrustUrl(
                userProfile?.data?.profilePictureBase64
              )
            " class="rounded-circle" width="40" height="40" />
      </div>

      <div class="title" *ngIf="!isCollapsed">
        <h6 class="mb-0">{{ userName }}</h6>
        <span class="">{{ userCode }}</span>
      </div>
      <button class="border-0 text-danger" (click)="onLogout()" tabindex="0" type="button" aria-label="logout"
        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="logout">
        <i class="mdi mdi-power"></i>
      </button>
    </div>
  </div>
</div>