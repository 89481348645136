import { Routes } from "@angular/router";
import { AuthGuard } from "./auth.guard";
import { BlankComponent } from "./layouts/blank/blank.component";
import { FullComponent } from "./layouts/full/full.component";
import { MaintenanceComponent } from "./shared/maintenance/maintenance.component";

export const Approutes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    component: FullComponent,
    children: [
      { path: "", redirectTo: "/authentication/login", pathMatch: "full" },
      {
        path: "resource-planner",
        loadChildren: () =>
          import("./views/resources/resources.module").then(
            (m) => m.ResourcesModule
          ),
      },
    ],
  },
  {
    path: "",
    component: BlankComponent,
    children: [
      {
        path: "authentication",
        loadChildren: () =>
          import("./authentication/authentication.module").then(
            (m) => m.AuthenticationModule
          ),
      },
    ],
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
  },
  {
    path: "**",
    redirectTo: "/authentication/404",
  },
];
