import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { SessionService } from "./session.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sessionService: SessionService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const sessionDetails = this.sessionService.getSessionDetails();
    
    if (sessionDetails.isSessionAlive) {
      return true;
    } else {
      this.router.navigate(["authentication/app-login"]);
      return false;
    }
  }
}
