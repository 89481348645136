<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <div class="d-flex align-items-center">
      <div class="navbar-brand">
        <i class="mdi mdi-menu toggle-button" (click)="onToggleSidebar()" *ngIf="!sidebarOpen && !isCollapsed"></i>
        <i class="mdi mdi-menu-open text-lg toggle-button" (click)="onToggleSidebar()"
          *ngIf="sidebarOpen && !isCollapsed"></i>
        <i class="mdi mdi-menu text-lg toggle-button" (click)="onToggleSidebar()"
          *ngIf="sidebarOpen && isCollapsed"></i>
      </div>
      <div class="navbar-title ml-3">
        <label>{{pageInfo?.title}}</label>
      </div>
    </div>
    <div class="ml-auto d-flex">
      <button class="btn btn-primary me-2 theme-toggle" (click)="toggleTheme()">
        <i class="mdi mdi-theme-light-dark"></i>
      </button>

      <!-- Layout Toggle Button -->
      <button class="btn btn-primary layout-toggle" (click)="toggleLayout()">
        <i class="mdi mdi-view-dashboard"></i>
      </button>

      <!-- <button class="btn btn-danger" (click)="onLogout()"><i class="mdi mdi-logout"></i> Log out</button> -->
    </div>
  </div>
</nav>