import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { SessionService } from '../../session.service';
import { CommunicationService } from '../../views/common/services/communication.service';
import { CompanySettingsServerResultsService } from '../../views/common/services/company-settings-server-results.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map, mergeMap } from 'rxjs';
import { ThemeService } from '../../views/common/services/theme.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {

  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Output() toggleLayoutEvent = new EventEmitter<void>();

  @Input() isCollapsed: Boolean;
  @Input() sidebarOpen: Boolean;
  @Input() sidebarType: String;
  isThemeToggle: boolean = false;
  isBoxedLayout: boolean = localStorage.getItem('layout') === 'Box' ? true : false; // Default value

  pageInfo: any;

  public expandLogo = false;
  companyDetails: any;

  constructor(private _location: Location,
    public sanitizer: DomSanitizer,
    public router: Router,
    private sessionServ: SessionService,
    public commuServ: CommunicationService,
    private settingsServer: CompanySettingsServerResultsService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private themeService: ThemeService
  ) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });
  }


  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    console.log(this.isBoxedLayout);

    this.settingsServer.getCompanyById(1).subscribe((res: any) => {
      this.companyDetails = res.data;
    })
  }


  onToggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

  onBack() {
    this._location.back();
  }

  onLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigateByUrl("/authentication/login")
  }


  toggleTheme() {
    this.isThemeToggle = !this.isThemeToggle;
    this.themeService.toggleTheme();
  }

  isDarkTheme() {
    return this.themeService.isDarkTheme();
  }

  toggleLayout() {
    this.isBoxedLayout = !this.isBoxedLayout;
    localStorage.setItem('layout', this.isBoxedLayout ? 'Box' : 'Full'); // Store preference in localStorage
    this.toggleLayoutEvent.emit();
  }
}
